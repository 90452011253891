import React from "react";
import { Link } from "react-router-dom";

const resourceChevronIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 72 70"
    width="36"
    height="35"
    className="tile-icon"
  >
    <path d="M0 9l9-9 33 35L9 70l-9-9 24-26zm30 0l9-9 33 35-33 35-9-9 24-26z" />
  </svg>
);

const ResourceTile = props => (
  <div className="tile">
    <a className="tile-link" href={props.href} target="hp-resources">
      <span className="col icon">{resourceChevronIcon}</span>
      <span className="col text">{props.children} &raquo;</span>
    </a>
  </div>
);

const ResourcesSection = () => (
  <div className="section resources">
    <div className="wrap">
      <h2 className="resources">Resources</h2>
      <div className="tile-frame">
        <ResourceTile href="http://www.wolfram.com/programming-lab/">
          Get Started with Programming Using Wolfram Programming Lab
        </ResourceTile>
        <ResourceTile href="http://www.wolfram.com/language/elementary-introduction/">
          Read Stephen Wolfram’s{" "}
          <i>An Elementary Introduction to the Wolfram Language</i>
        </ResourceTile>
        <ResourceTile href="http://www.wolfram.com/language/fast-introduction-for-programmers/">
          Fast Introduction for Programmers
        </ResourceTile>
        <ResourceTile href="http://www.wolfram.com/language/fast-introduction-for-math-students/">
          Fast Introduction for Math Students
        </ResourceTile>
      </div>
      <h2 className="contribute">Contribute</h2>

      <div className="tile-frame">
        <div className="tile">
          <p>Have an idea for a new Challenge? Become a contributor!</p>
          <p className="button-frame">
            <a
              href={
                process.env.REACT_APP_CHALLENGES_CLOUD_ROOT +
                "/nb/d/AuthoringTemplate.nb"
              }
              className="button"
              target="authoringnotebook"
            >
              Download Authoring Notebook
            </a>
          </p>
        </div>
        <div className="tile">
          <p>Send us a message!</p>
          <p className="button-frame">
            <Link to="/contact" className="button">
              Contact Us
            </Link>
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default ResourcesSection;
