import "react-app-polyfill/ie11";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import assign from "lodash/assign";
import find from "lodash/find";
import toSlugCase from "to-slug-case";
import registerServiceWorker from "./registerServiceWorker";
import DocumentTitle from "react-document-title";

import "normalize.css";
import "./assets/global.css";

import ScrollToTop from "./components/ScrollToTop";
import Header from "./components/Header";
import Footer from "./components/Footer";
import NotFound from "./components/NotFound";
import HomePage from "./pages/Home";
import ChallengePage from "./pages/Challenge";
import TrackPage from "./pages/Track";
import LeaderBoard from "./pages/LeaderBoard";
import ViewProfilePage from "./pages/ViewProfile";
import EditProfilePage from "./pages/EditProfile";
import ContactPage from "./pages/Contact";
import {
  AllPage,
  CategoryPage,
  RecentPage,
  PopularPage,
  SolvedByMePage,
  UnsolvedByMePage
} from "./pages/Category";

import categoryList from "./components/categoryList";

class PrimaryLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userAuthenticated: null, // null, true, or false
      categories: categoryList,
      challenges: JSON.parse(localStorage.getItem("challenges")) || {},
      leaderBoards: JSON.parse(localStorage.getItem("leaderBoards")) || [],
      tracks: JSON.parse(localStorage.getItem("tracks")) || [],
      profileData: null // null, false, or object
    };

    this.reloadProfile = this.reloadProfile.bind(this);
  }
  reloadProfile() {
    this.setState({ profileData: null }, () => {
      fetch(
        process.env.REACT_APP_CHALLENGES_CLOUD_ROOT + "/api/profile/get",
        { credentials: "include" }
      )
        .then(response => response.json())
        .then(data => {
          if (data.status === 200) {
            this.setState({ profileData: data });
          } else {
            this.setState({ profileData: false });
          }
        });
    });
  }
  componentDidMount() {
    const apiLocation = process.env.REACT_APP_CHALLENGES_CLOUD_ROOT + "/site/";
    fetch(apiLocation + "leaderboard.json")
      .then(response => response.json())
      .then(leaderboards => {
        this.setState({ leaderBoards: leaderboards });
        localStorage.setItem("leaderBoards", JSON.stringify(leaderboards));
      });
    fetch(apiLocation + "tracks.json")
      .then(response => response.json())
      .then(data => {
        const tracks = data.map(track => ({
          Title: track.Track,
          TitleSlug: toSlugCase(track.Track),
          Keys: track.Data.map(challenge => challenge.Key)
        }));
        this.setState({ tracks: tracks });
        localStorage.setItem("tracks", JSON.stringify(tracks));
      });
    fetch(apiLocation + "challenges.json")
      .then(response => response.json())
      .then(data => {
        const challenges = data.all;
        this.setState({ challenges: challenges });
        localStorage.setItem("challenges", JSON.stringify(challenges));
        return fetch(
          process.env.REACT_APP_CHALLENGES_CLOUD_ROOT + "/api/userSolvedData",
          { credentials: "include" }
        );
      })
      .then(response => response.json())
      .then(data => {
        if (data.status === 200) {
          this.setState({
            userAuthenticated: true,
            challenges: this.state.challenges.map(obj => {
              return assign(obj, find(data.data, { Key: obj.Key }));
            })
          });
        } else {
          this.setState({
            userAuthenticated: false
          });
        }
      });
    this.reloadProfile();
  }
  render() {
    const needsHTTPS = [
      "challenges.wolfram.com",
      "challenges.wolframcloud.com",
      "challenges.test.wolfram.com",
      "challenges.test.wolframcloud.com",
      "www.wolframcloud.com",
      "www.test.wolframcloud.com",
      "www.devel.wolframcloud.com"
    ];

    function requiresHTTPS(hostname) {
      return needsHTTPS.indexOf(hostname) > -1;
    }

    function forceHTTPS() {
      let loc = window.location;
      if (loc.protocol !== "https:" && requiresHTTPS(loc.hostname)) {
        let url = loc.href.substring(loc.protocol.length);
        window.location.href = "https:" + url;
      }
    }

    forceHTTPS();

    const userAuthenticated = this.state.userAuthenticated;
    const challenges = this.state.challenges;
    const categories = this.state.categories;
    const leaderBoards = this.state.leaderBoards;
    const tracks = this.state.tracks;
    const profileData = this.state.profileData;

    return (
      <ScrollToTop>
        <Header userAuthenticated={userAuthenticated} />
        <Switch>
          <Route
            path="/"
            exact
            render={props => (
              <HomePage
                {...props}
                challenges={challenges}
                categories={categories}
                tracks={tracks}
              />
            )}
          />
          <Route
            path="/challenge/:slug"
            render={props => (
              <ChallengePage
                {...props}
                challenges={challenges}
                leaderBoards={leaderBoards}
              />
            )}
          />
          <Redirect from="/challenge" to="/" />
          <Route
            path="/all"
            render={props => (
              <AllPage
                {...props}
                challenges={challenges}
                categories={categories}
                userAuthenticated={userAuthenticated}
              />
            )}
          />
          <Redirect from="/all.html" to="/all" />
          <Route
            path="/recent"
            render={props => (
              <RecentPage
                {...props}
                challenges={challenges}
                categories={categories}
                userAuthenticated={userAuthenticated}
              />
            )}
          />
          <Redirect from="/recent.html" to="/recent" />
          <Route
            path="/solved-by-me"
            render={props => (
              <SolvedByMePage
                {...props}
                challenges={challenges}
                categories={categories}
                userAuthenticated={userAuthenticated}
              />
            )}
          />
          <Route
            path="/unsolved-by-me"
            render={props => (
              <UnsolvedByMePage
                {...props}
                challenges={challenges}
                categories={categories}
                userAuthenticated={userAuthenticated}
              />
            )}
          />
          <Route
            path="/categories/:category"
            render={props => (
              <CategoryPage
                {...props}
                challenges={challenges}
                categories={categories}
                userAuthenticated={userAuthenticated}
              />
            )}
          />
          <Redirect from="/categories/" to="/all" />
          <Route
            path="/popular"
            render={props => (
              <PopularPage
                {...props}
                challenges={challenges}
                categories={categories}
                userAuthenticated={userAuthenticated}
              />
            )}
          />
          <Redirect from="/popular.html" to="/popular" />
          <Route
            path="/tracks/:slug"
            render={props => (
              <TrackPage {...props} tracks={tracks} challenges={challenges} />
            )}
          />
          <Redirect from="/tracks" to="/" />
          <Route
            path="/leaderboard"
            render={props => (
              <LeaderBoard {...props} leaderBoards={leaderBoards} />
            )}
          />
          <Redirect from="/leader.html" to="/leaderboard" />
          <Route
            path="/profile/edit"
            render={props => (
              <EditProfilePage
                {...props}
                reloadProfile={this.reloadProfile}
                profileData={profileData}
              />
            )}
          />
          <Route
            path="/profile/"
            render={props => (
              <ViewProfilePage {...props} profileData={profileData} />
            )}
          />
          <Route path="/contact/" component={ContactPage} />
          <Route path="/" component={NotFound} />
        </Switch>
        <Footer />
      </ScrollToTop>
    );
  }
}


const App = () => {
    const dispatch = process.env.REACT_APP_CHALLENGES_DISPATCH;
    return (
        <DocumentTitle title="Wolfram Challenges: Programming Puzzles for the Wolfram Language">
            <BrowserRouter basename={dispatch}>
                <PrimaryLayout/>
            </BrowserRouter>
        </DocumentTitle>
    )
};

ReactDOM.render(<App />, document.getElementById("root"));
registerServiceWorker();
