import React from "react";
import { Link } from "react-router-dom";

import cloudLogo from "./cloud-logo.png";
import "./style.css";

const Footer = () => {
  return (
    <footer id="wc-footer">
      <div className="rowMain">
        <img src={cloudLogo} width={40} height={25} alt="" />
        <p>
          <a
            href={process.env.REACT_APP_CHALLENGES_CLOUD_BASE}
            className="link"
            target="wc-footer"
          >
            Powered by the Wolfram Cloud
          </a>
          <span className="row">
            <a
              href="https://www.wolfram.com/technologies/"
              className="link"
              target="wc-footer"
            >
              More about Wolfram Technology
            </a>
            <Link to="/contact" className="link">
              Contact Us
            </Link>
          </span>
        </p>
      </div>
      <div className="rowSecondary">
        <p>
          <span className="link">
            &copy; {new Date().getFullYear()}{" "}
            <a href="https://www.wolfram.com/" target="wc-footer">
              Wolfram Research, Inc
            </a>. All rights reserved.
          </span>
          <span className="row">
            <a
              href="https://www.wolfram.com/legal/terms/wolfram-cloud.html"
              className="link"
              target="wc-footer"
            >
              Terms of Use
            </a>
            <a
              href="https://www.wolfram.com/legal/privacy/wolfram-cloud.html"
              className="link"
              target="wc-footer"
            >
              Privacy
            </a>
          </span>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
