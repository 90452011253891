import React from "react";
import DocumentTitle from "react-document-title";
import { Link } from "react-router-dom";

import ProfileLoader from "../../components/ProfileLoader";

import "./style.css";

const ViewProfile = props => {
  const returnURL = encodeURIComponent(process.env.REACT_APP_CHALLENGES_FULL_DISPATCH);
  const profileData = props.profileData;
  return (
    <DocumentTitle
      title={profileData.Username + "'s Profile | Wolfram Challenges"}
    >
      <main id="ViewProfilePage">
        <div className="wrap">
          <h1>Profile</h1>
          <section id="user-information">
            <h2>User Information</h2>
            <div className="row">
              <div className="col">
                <h3 className="visuallyhidden">Profile Image</h3>
                <p className="profile-image">
                  <img src={profileData.ProfileImage} alt="" />
                </p>
              </div>
              <div className="col">
                <h3>Wolfram ID</h3>
                <p>{profileData.WolframID}</p>
                <h3>Challenges Username</h3>
                <p>{profileData.Username}</p>
                <h3>Location</h3>
                <p>{profileData.Location}</p>
              </div>
            </div>
            <h3 className="visuallyhidden">Profile Actions</h3>
            <p className="profile-actions">
              <Link to="/profile/edit" className="button main">
                Edit Profile
              </Link>
              <a
                href={
                  process.env.REACT_APP_CHALLENGES_CLOUD_BASE +
                  "/j_spring_security_logout?targetUrl=" +
                  returnURL
                }
                className="button"
              >
                Sign Out
              </a>
            </p>
          </section>
        </div>
      </main>
    </DocumentTitle>
  );
};

const ProfilePageWrapper = props => {
  const profileData = props.profileData;
  return (
    <ProfileLoader profileData={profileData}>
      <ViewProfile profileData={profileData} />
    </ProfileLoader>
  );
};

export default ProfilePageWrapper;
