import React from "react";
import { Link } from "react-router-dom";

const TrackButton = props => (
  <Link
    to={"/tracks/" + props.slug}
    className={"tile " + props.slug}
    title={props.title}
  >
    <span className="fg">
      <span className="text">{props.title}</span>
    </span>
  </Link>
);

const TrackButtons = props => {
  const tracks = props.tracks;
  return (
    <ul className="track-tiles">
      {tracks.map(track => (
        <li key={track.TitleSlug}>
          <TrackButton title={track.Title} slug={track.TitleSlug} />
        </li>
      ))}
      {/* <li>
        <span className="tile coming-soon">
          <span className="fg">
            <span className="text">More coming soon&hellip;</span>
          </span>
        </span>
      </li> */}
    </ul>
  );
};

const ChallengeTracks = props => (
  <div id="tracks" className="section challenge-tracks">
    <div className="wrap">
      <p>
        An expanding collection of Challenges for both beginners and experienced
        programmers. Find a track that fits your interests in coding, math, word
        puzzles, computational thinking and more&hellip;
      </p>
      <p>
        <Link to="/all" className="button">
          All Challenges &raquo;
        </Link>
      </p>
      <h2 className="visuallyhidden">Challenge Tracks</h2>
      <TrackButtons tracks={props.tracks} />
    </div>
  </div>
);

export default ChallengeTracks;
