import React from "react";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import DocumentTitle from "react-document-title";
import ReactTooltip from "react-tooltip";

import ChallengeView from "../../components/ChallengeView";
import Loading from "../../components/Loading";
import NotFound from "../../components/NotFound";

import "./style.css";

const ChallengePage = props => {
  return (
    <DocumentTitle title={props.challenge.Title + " | Wolfram Challenges"}>
      <main id="ChallengePage">
        <div className="wrap">
          <ChallengeView
            challenge={props.challenge}
            leaderBoard={props.leaderBoard}
            showMoreDetails={true}
            showLeaderBoard={true}
          />
        </div>
        <ReactTooltip className="custom-tooltip" place="bottom" />
      </main>
    </DocumentTitle>
  );
};

const ChallengePageWrapper = props => {
  const selectedChallenge = find(props.challenges, {
    TitleSlug: props.match.params.slug
  });
  const selectedLeaderBoard = find(props.leaderBoards, {
    TitleSlug: props.match.params.slug
  });
  if (isEmpty(props.challenges)) {
    return <Loading />;
  } else if (isEmpty(selectedChallenge)) {
    return <NotFound />;
  } else {
    return (
      <ChallengePage
        challenge={selectedChallenge}
        leaderBoard={selectedLeaderBoard}
      />
    );
  }
};

export default ChallengePageWrapper;
