import React, { Component } from "react";
import { Link } from "react-router-dom";
import isEmpty from "lodash/isEmpty";

import ChallengeCategoryList from "../ChallengeCategoryList";
import ChallengeSolvedDate from "../ChallengeSolvedDate";
import LeaderBoardTile from "../../components/LeaderBoardTile";

import "./style.css";

const ChallengeViewHeader = props => {
  const challenge = props.challenge;
  if (props.renderAsPartOfList) {
    return (
      <h2>
        <Link to={"/challenge/" + challenge.TitleSlug}>
          {challenge.Title} &raquo;
        </Link>
      </h2>
    );
  } else {
    return <h1>{challenge.Title}</h1>;
  }
};
class MoreDetails extends Component {
  constructor(props) {
    super(props);
    this.state = { moreDetails: { __html: "" } };
  }
  componentDidMount() {
    const props = this.props;
    fetch(
      `${process.env.REACT_APP_CHALLENGES_CLOUD_ROOT}/nb/export/${
        props.challenge.ProblemID
      }/MoreDetails.html`
    )
      .then(response => response.text())
      .then(data => this.setState({ moreDetails: { __html: data } }))
      .catch(() => this.setState({ moreDetails: false }));
  }
  componentDidUpdate() {
    const $ = window.jQuery;

    $("#more-details.loaded .Input > img")
      .wrap('<div class="img-frame"/>')
      .parent()
      .append(
        '<svg xmlns="http://www.w3.org/2000/svg" class="copy-icon" width="30" height="30">' +
          '<circle cx="15" cy="15" r="15" fill="#e0e0e0"/>' +
          '<path d="M14 5v1h-2v3h6V6h-2V5zM8 6v18h14V6h-3v1h2v16H9V7h2V6zm5 1h4v1h-4z" fill="#7d7d7d"/>' +
          '<path class="no-checked" d="M11 11h8v1h-8zM11 14h8v1h-8zM11 17h8v1h-8zM11 20h8v1h-8z" fill="#7d7d7d"/>' +
          '<path class="checked" d="M11 16l1-1 2 2 4-4 1 1-5 5z" fill="#04b000"/>' +
          "</svg>"
      );
    new window.ClipboardJS("#more-details.loaded .Input .img-frame", {
      text: function(trigger) {
        return trigger.children[0].getAttribute("alt");
      }
    }).on("success", function(e) {
      var $input = $(e.trigger);
      $input.addClass("checked");
      setTimeout(function() {
        $input.removeClass("checked");
      }, 5000);
    });
  }
  render() {
    const moreDetailsContent = this.state.moreDetails;
    var moreDetailsSection;
    if (moreDetailsContent) {
      if (moreDetailsContent.__html === "") {
        moreDetailsSection = (
          <div id="more-details" className="loading">
            <h2>Loading details...</h2>
          </div>
        );
      } else {
        moreDetailsSection = (
          <div id="more-details" className="loaded">
            <div
              className="more-details-content"
              dangerouslySetInnerHTML={moreDetailsContent}
            />
          </div>
        );
      }
      return moreDetailsSection;
    } else {
      return null;
    }
  }
}
const Authors = props => {
  const authors = props.authors.map(author => {
    return (
      <span className="line" key={author}>
        {author}
        <br />
      </span>
    );
  });
  return <p className="challenge-categories author-information">{authors}</p>;
};

const ChallengeView = props => {
  const challenge = props.challenge;
  const renderAsPartOfList = props.renderAsPartOfList;
  const description = { __html: challenge.WebDescription };

  return (
    <div
      className={
        "challenge-view " +
        (renderAsPartOfList ? "tile " : " ") +
        (challenge.hasBeenSolved ? "solved" : " ")
      }
    >
      <ChallengeViewHeader
        renderAsPartOfList={renderAsPartOfList}
        challenge={challenge}
      />
      <p className="description" dangerouslySetInnerHTML={description} />
      {props.showMoreDetails ? <MoreDetails challenge={challenge} /> : ""}
      <ChallengeSolvedDate challenge={challenge} />
      <p className="notebook-buttons">
        <a
          className="button"
          target="notebook-button"
          href={challenge.NotebookURL.Desktop}
        >
          Download
        </a>
        <a
          className="button"
          target="notebook-button"
          href={challenge.NotebookURL.Cloud}
        >
          Open in Cloud
        </a>
      </p>
      <ChallengeCategoryList categories={challenge.TagData} />
      {challenge.AuthorInformation ? (
        <Authors authors={challenge.AuthorInformation} />
      ) : (
        ""
      )}
      {props.showLeaderBoard && !isEmpty(props.leaderBoard) ? (
        <LeaderBoardTile
          title="Leaderboard"
          challenge={challenge}
          leaderBoardData={props.leaderBoard.Data}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default ChallengeView;
