import React, { Component } from "react";
import DocumentTitle from "react-document-title";

import "./style.css";

class ContactPage extends Component {
  componentDidUpdate() {
    const $ = window.jQuery;

    // from https://stash.wolfram.com/projects/DS/repos/resourcesystem/browse/ResourceSystem/Kernel/Repositories/DataRepository/Website/webresources/main.js?at=de8ad5ee40716db7e808fee7ca70eac06e4b1ab6
    // this is not react-y because we wanted to steal code from Data Repository.

    $(function() {
      return $("#contact-form")
        .attr("novalidate", "novalidate")
        .submit(function(e) {
          var $form, $submit;
          e.preventDefault();
          $form = $(this);
          $submit = $form.find(":submit");
          $form.find(".error").remove();
          $form.find(".invalid").removeClass("invalid");
          $form.find(".text [required]").each(function() {
            var $input;
            $input = $(this);
            if ($input.val() === "") {
              return $input
                .addClass("invalid")
                .after('<span class="error">This field is required</span>');
            }
          });
          $form.find(".checkbox [required]").each(function() {
            var $input;
            $input = $(this);
            if (!$input.prop("checked")) {
              return $input
                .parent()
                .after('<span class="error">This field is required</span>');
            }
          });
          $form.find(".text [type=email]").each(function() {
            var $input, inputval;
            $input = $(this);
            inputval = $input.val();
            if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(inputval) &&
              inputval !== ""
            ) {
              return $input
                .addClass("invalid")
                .after(
                  '<span class="error">Please enter a valid email address</span>'
                );
            }
          });
          if ($form.find(".error").length === 0) {
            $submit.prop("disabled", true).text("Sending\u2026");
            return $
              .post($form.prop("action") + "?" + $form.serialize())
              .done(function(msg) {
                return $form.html(
                  '<p class="line success">Thanks for sending us a message and we will respond as soon as we can.</p>'
                );
              })
              .fail(function(error) {
                console.log(error);
                if (
                  error.status === 400 &&
                  error.responseJSON.Failure != null
                ) {
                  return $submit
                    .prop("disabled", false)
                    .text("Retry")
                    .after(
                      '<span class="error">' +
                        error.responseJSON.Failure +
                        "</span>"
                    );
                } else {
                  return $submit
                    .prop("disabled", false)
                    .text("Retry")
                    .after(
                      '<span class="error">There was a problem with sending the form, please try again in a couple of minutes, or <a href="https://www.wolfram.com/support/contact/email/">send us an email</a>.</span>'
                    );
                }
              });
          }
        });
    });
  }
  render() {
    // form from https://stash.wolfram.com/projects/DS/repos/resourcesystem/browse/ResourceSystem/Kernel/Repositories/DataRepository/Website/webresources/contact-us.xml?at=0761f9639a9c2203eccc011bf94b3ece8ee24f6c#35
    return (
      <DocumentTitle title="Send us a message! | Wolfram Challenges">
        <main id="ContactPage">
          <div className="wrap">
            <h1>Send us a message!</h1>
            <form
              id="contact-form"
              action={
                process.env.REACT_APP_CHALLENGES_CLOUD_ROOT + "/api/contact"
              }
              method="POST"
            >
              <p className="line text">
                <label htmlFor="name">
                  Your name<span className="req">*</span>
                </label>
                <br />
                <input name="name" id="name" required="required" type="text" />
              </p>
              <p className="line text">
                <label htmlFor="email">
                  Email address<span className="req">*</span>
                </label>
                <br />
                <input
                  name="email"
                  id="email"
                  required="required"
                  type="email"
                />
              </p>
              <p className="line text">
                <label htmlFor="organization">Organization name</label>
                <br />
                <input name="organization" id="organization" type="text" />
              </p>
              <p className="line text">
                <label htmlFor="message">
                  Message<span className="req">*</span>
                </label>
                <br />
                <textarea
                  name="message"
                  id="message"
                  rows="3"
                  required="required"
                />
              </p>
              <p className="line checkbox">
                <label>
                  <input
                    name="agreementchecked"
                    value="true"
                    required="required"
                    type="checkbox"
                  /> I agree to the retention of my personal data as explained
                  in the&nbsp;<a href="https://www.wolfram.com/legal/privacy/wolfram-research.html">
                    privacy policy »
                  </a>
                </label>
              </p>
              <p className="line submit">
                <button>Submit</button>
              </p>
            </form>
          </div>
        </main>
      </DocumentTitle>
    );
  }
}

export default ContactPage;
