import React from "react";

import Loading from "../Loading";
import PleaseLogIn from "../PleaseLogIn";

const ProfilePageLoader = props => {
  const profileData = props.profileData;
  if (profileData === null) {
    return <Loading />;
  } else if (profileData === false) {
    return <PleaseLogIn />;
  } else {
    return props.children;
  }
};

export default ProfilePageLoader;
