import React from "react";
import isEmpty from "lodash/isEmpty";
import DocumentTitle from "react-document-title";
import ReactTooltip from "react-tooltip";

import LeaderBoardTile from "../../components/LeaderBoardTile";
import Loading from "../../components/Loading";

import "./style.css";

const LeaderBoardPage = props => (
  <DocumentTitle title="Leaderboard | Wolfram Challenges">
    <main id="LeaderBoardPage">
      <div className="wrap">
        <h1>Leaderboard</h1>
        <div className="leader-boards">
          {props.leaderBoards.map(leaderBoard => (
            <LeaderBoardTile
              key={leaderBoard.TitleSlug}
              leaderBoardData={leaderBoard.Data}
              title={leaderBoard.Title}
              linkTo={"/challenge/" + leaderBoard.TitleSlug}
            />
          ))}
        </div>
      </div>
      <ReactTooltip className="custom-tooltip" place="bottom" />
    </main>
  </DocumentTitle>
);

const LeaderBoardPageWrapper = props => {
  const leaderBoards = props.leaderBoards;
  if (isEmpty(leaderBoards)) {
    return <Loading />;
  } else {
    return <LeaderBoardPage leaderBoards={leaderBoards} />;
  }
};

export default LeaderBoardPageWrapper;
