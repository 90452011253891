import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import "./style.css";

const ChallengeCategoryList = props => {
  const categories = props.categories;
  if (categories.length === 0) {
    return null;
  } else {
    return (
      <p className="challenge-categories">
        Categories:&ensp;
        {categories.map(category => (
          <Fragment key={category.TagSlug}>
            <Link to={"/categories/" + category.TagSlug}>
              {category.Tag}
            </Link>&emsp;
          </Fragment>
        ))}
      </p>
    );
  }
};

export default ChallengeCategoryList;
