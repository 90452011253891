import React from "react";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import DocumentTitle from "react-document-title";

import ChallengeView from "../../components/ChallengeView";
import Loading from "../../components/Loading";
import NotFound from "../../components/NotFound";

import "./style.css";

const TrackPage = props => {
  const track = props.track;
  return (
    <DocumentTitle title={track.Title + " Track | Wolfram Challenges"}>
      <main id="TrackPage">
        <div className="wrap">
          <h1>{track.Title}</h1>
          {track.Keys.map(key => {
            const challenge = find(props.challenges, { Key: key });
            if (challenge) {
              return (
                <ChallengeView
                  key={challenge.TitleSlug}
                  challenge={challenge}
                  renderAsPartOfList={true}
                />
              );
            }
            return null;
          })}
        </div>
      </main>
    </DocumentTitle>
  );
};

const TrackPageWrapper = props => {
  const selectedTrack = find(props.tracks, {
    TitleSlug: props.match.params.slug
  });
  if (isEmpty(props.challenges) && isEmpty(props.tracks)) {
    return <Loading />;
  } else if (isEmpty(selectedTrack)) {
    return <NotFound />;
  } else {
    return <TrackPage track={selectedTrack} challenges={props.challenges} />;
  }
};

export default TrackPageWrapper;
