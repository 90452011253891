import React from "react";
import DocumentTitle from "react-document-title";

import "./style.css";

const PleaseLogIn = props => (
  <DocumentTitle title="Please Log In | Wolfram Challenges">
    <main id="PleaseLogIn">
      <h1>Please Log In</h1>
      <p>
        <span className="nobr">
          Please log in to the Wolfram Cloud using the button below.
        </span>
      </p>
      <p className="button-frame">
        <a
          href={
              process.env.REACT_APP_CHALLENGES_CLOUD_BASE +
              "/app/j_spring_oauth_security_check?targetUrl=" +
              encodeURIComponent(process.env.PUBLIC_URL)
          }
          className="button"
        >
          Log In &raquo;
        </a>
      </p>
    </main>
  </DocumentTitle>
);

export default PleaseLogIn;
