import React, { PureComponent } from "react";
import DocumentTitle from "react-document-title";
import { Link, Redirect } from "react-router-dom";
import Dropzone from "react-dropzone";

import ProfileLoader from "../../components/ProfileLoader";

import "./style.css";

class EditProfile extends PureComponent {
  constructor(props) {
    super(props);
    const profileData = this.props.profileData;
    this.state = {
      isSubmitting: false,
      submitSuccessful: null,
      errorMessage: "There was an error in submitting this form, please try again",
      Username: profileData.Username,
      Location: profileData.Location,
      ProfileImageFile: null,
      ProfileImagePreview: profileData.ProfileImage
    };
    this.fileInput = React.createRef();

    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleTextChange(event) {
    const target = event.target;
    this.setState({ [target.name]: target.value });
  }
  handleImageChange(files) {
    this.componentWillUnmount();
    const imageFile = files[0];
    this.setState({
      ProfileImageFile: imageFile,
      ProfileImagePreview: URL.createObjectURL(imageFile)
    });
  }
  handleSubmit(event) {
    event.preventDefault();

    var formData = new FormData();

    formData.append("Username", this.state.Username);
    formData.append("Location", this.state.Location);
    if (this.state.ProfileImageFile) {
      formData.append("ProfileImage", this.state.ProfileImageFile);
    }

    this.setState({ isSubmitting: true }, () => {
      fetch(
        process.env.REACT_APP_CHALLENGES_CLOUD_ROOT + "/api/profile/post",
        {
          method: "post",
          credentials: "include",
          body: formData
        }
      )
        .then(response => response.json())
        .then(data => {
          if (data.Success === true) {
            this.setState({ isSubmitting: false, submitSuccessful: true });
            this.props.reloadProfile();
          } else {
            this.setState({ isSubmitting: false, submitSuccessful: false, errorMessage: data.Failure });
          }
        })
        .catch(error => {
          this.setState({ isSubmitting: false, submitSuccessful: false });
        });
    });
  }
  componentWillUnmount() {
    URL.revokeObjectURL(this.state.ProfileImagePreview);
  }
  render() {
    if (this.state.submitSuccessful) {
      return <Redirect push to="/profile/" />;
    } else {
      return (
        <DocumentTitle title="Edit Profile | Wolfram Challenges">
          <main id="EditProfilePage">
            <div className="wrap">
              <h1>Edit Profile</h1>
              <form onSubmit={this.handleSubmit}>
                <p className="line text">
                  <label htmlFor="username">Username</label>
                  <br />
                  <input
                    value={this.state.Username}
                    onChange={this.handleTextChange}
                    type="text"
                    id="username"
                    name="Username"
                  />
                </p>
                {/* <div>
                <label htmlFor="file">Choose image to upload</label>
                <input
                  ref={this.fileInput}
                  type="file"
                  id="file"
                  name="ProfileImage"
                />
              </div> */}
                <Dropzone onDrop={this.handleImageChange}>
                  {({ getRootProps, getInputProps, isDragActive }) => {
                    return (
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p className="line text">
                          {isDragActive ? (
                            <label>Drop files here...</label>
                          ) : (
                            <label>
                              Drag and drop an image (or click to browse)
                            </label>
                          )}
                          <br />
                          <span
                            className={
                              "drop-frame " + (isDragActive ? "active" : "")
                            }
                          >
                            <span
                              className="profile-image-preview"
                              style={{
                                backgroundImage: `url(${
                                  this.state.ProfileImagePreview
                                })`
                              }}
                            />
                          </span>
                        </p>
                      </div>
                    );
                  }}
                </Dropzone>
                <p className="line text">
                  <label htmlFor="location">Country</label>
                  <br />
                  <input
                    value={this.state.Location}
                    onChange={this.handleTextChange}
                    type="text"
                    id="location"
                    name="Location"
                  />
                </p>
                <div className="profile-actions">
                  <button
                    className="button main"
                    disabled={this.state.isSubmitting}
                  >
                    {this.state.isSubmitting ? "Sending" : "Submit"}
                  </button>
                  <Link to="/profile/" className="button">
                    Cancel
                  </Link>
                </div>
                {this.state.submitSuccessful === false ? (
                  <p className="error">
                    {this.state.errorMessage}
                  </p>
                ) : (
                  ""
                )}
              </form>
            </div>
          </main>
        </DocumentTitle>
      );
    }
  }
}

const ProfilePageWrapper = props => {
  const profileData = props.profileData;
  return (
    <ProfileLoader profileData={profileData}>
      <EditProfile
        profileData={profileData}
        reloadProfile={props.reloadProfile}
      />
    </ProfileLoader>
  );
};

export default ProfilePageWrapper;
