import React from "react";
import { Link } from "react-router-dom";
import DocumentTitle from "react-document-title";

import "./style.css";

const NotFound = props => (
  <DocumentTitle title="Page Not Found | Wolfram Challenges">
    <main id="NotFound">
      <h1>Page Not Found</h1>
      <p>
        <span className="nobr">We&rsquo;re sorry, but we were not able to</span>{" "}
        <span className="nobr">find the page that you asked for.</span>
      </p>
      <p className="button-frame">
        <Link to="/" className="button">
          Go to the Wolfram Challenges homepage &raquo;
        </Link>
      </p>
    </main>
  </DocumentTitle>
);

export default NotFound;
