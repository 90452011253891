import React from "react";
import { Link } from "react-router-dom";

import ChallengeCategoryList from "../ChallengeCategoryList";
import ChallengeSolvedDate from "../ChallengeSolvedDate";

import "./style.css";

const ChallengeTile = props => {
  const challenge = props.challenge;
  const description = { __html: challenge.WebDescription };

  return (
    <div className={"challenge " + (challenge.hasBeenSolved ? "solved" : "")}>
      <h3>
        <Link to={"/challenge/" + challenge.TitleSlug}>
          {challenge.Title} &raquo;
        </Link>
      </h3>
      <p dangerouslySetInnerHTML={description} />
      <ChallengeSolvedDate challenge={challenge} />
      <ChallengeCategoryList categories={challenge.TagData} />
    </div>
  );
};

const ChallengeList = props => {
  const challenges = props.challenges;
  if (challenges.length === 0) {
    return (
      <div id="no-challenges-found">
        <h3>No Challenges Found</h3>
      </div>
    );
  } else {
    return (
      <div className="challenge-list">
        {props.challenges.map(challenge => (
          <ChallengeTile key={challenge.TitleSlug} challenge={challenge} />
        ))}
      </div>
    );
  }
};

export default ChallengeList;
