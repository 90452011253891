import React from "react";

import "./style.css";

const loadingIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 2 2"
    className="loading-icon"
  >
    <path fill="#2db1c1" d="M0 0h1v1h-1z" />
    <path fill="#022b42" d="M1 0h1v1h-1z" />
    <path fill="#f7ea5c" d="M0 1h1v1h-1z" />
    <path fill="#ff4d2c" d="M1 1h1v1h-1z" />
  </svg>
);

const Loading = props => (
  <main id="Loading">
    <h1 role="alert" aria-busy="true">
      Loading&hellip;
    </h1>
    <div className="icon-frame">{loadingIcon}</div>
  </main>
);

export default Loading;
