import React, { PureComponent, Fragment } from "react";
import concat from "lodash/concat";
import orderBy from "lodash/orderBy";
import shuffle from "lodash/shuffle";
import filter from "lodash/filter";
import reject from "lodash/reject";
import some from "lodash/some";

import ChallengeList from "./ChallengeList";

const ToggleButton = props => {
  const label = props.label;
  const slug = props.slug;
  const id = props.name + "-" + slug;
  return (
    <span className="toggle-button">
      <input
        type="radio"
        name={props.name}
        value={slug}
        id={id}
        onChange={props.onClick}
        checked={props.isActive}
      />
      <label htmlFor={id}>{label}</label>
    </span>
  );
};

class ChallengeListController extends PureComponent {
  getInitialState = () => {
    return {
      sortBy: this.props.defaultSortBy || "name",
      categoryFilter: "all",
      solvedFilter: this.props.defaultFilter || "all",
      searchFilter: ""
    };
  };

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }
  // change this to getDerivedStateFromProps when "react" updates to 16.3
  componentWillReceiveProps() {
    this.setState(this.getInitialState());
  }

  toggleSort = event => {
    this.setState({ sortBy: event.target.value });
  };
  toggleCategoryFilter = event => {
    this.setState({ categoryFilter: event.target.value });
  };
  toggleSolvedFilter = event => {
    this.setState({ solvedFilter: event.target.value });
  };
  changeSearchFilter = event => {
    this.setState({ searchFilter: event.target.value });
  };

  render() {
    var challenges = this.props.challenges;
    const categories = concat(
      { Title: "All", Slug: "all" },
      this.props.categories
    );

    // filter challenges by category
    if (this.state.categoryFilter !== "all") {
      challenges = filter(challenges, challenge => {
        return some(challenge.TagData, { TagSlug: this.state.categoryFilter });
      });
    }

    //filter challenges by solved
    if (this.state.solvedFilter === "solved") {
      challenges = filter(challenges, { Solved: true });
    }
    if (this.state.solvedFilter === "unsolved") {
      challenges = filter(challenges, { Solved: false });
    }
    if (this.state.solvedFilter === "solvedByUser") {
      challenges = filter(challenges, { hasBeenSolved: true });
    }
    if (this.state.solvedFilter === "unsolvedByUser") {
      challenges = reject(challenges, { hasBeenSolved: true });
    }

    //filter challenges by search
    if (this.state.searchFilter !== "") {
      challenges = filter(challenges, challenge => {
        const searchFilter = this.state.searchFilter.toLowerCase();

        const title = challenge.Title.toLowerCase();
        const description = challenge.WebDescription.toLowerCase();
        const categories = challenge.Tags.join().toLowerCase();

        return (
          title.indexOf(searchFilter) !== -1 ||
          description.indexOf(searchFilter) !== -1 ||
          categories.indexOf(searchFilter) !== -1
        );
      });
    }

    // sort challenges
    switch (this.state.sortBy) {
      case "recent":
        challenges = orderBy(challenges, "Date", "desc");
        break;
      case "popularity":
        challenges = orderBy(challenges, "Count", "desc");
        break;
      case "shuffle":
        challenges = shuffle(challenges);
        break;
      default:
        challenges = orderBy(challenges, "Title");
    }

    var sortByDropdown = "";
    if (this.props.defaultSortBy == null) {
      sortByDropdown = (
        <div className="controller-group">
          <span className="controller-group-label">Sort by:&ensp;</span>
          <select value={this.state.sortBy} onChange={this.toggleSort}>
            <option value="name">Name</option>
            <option value="recent">Recent</option>
            <option value="popularity">Popularity</option>
            {/* <option value="shuffle">Shuffle</option> */}
          </select>
        </div>
      );
    }

    var solvedFilterDropdown = "";
    if (this.props.defaultFilter == null) {
      solvedFilterDropdown = (
        <div className="controller-group">
          <span className="controller-group-label">Filter:&ensp;</span>
          <select
            value={this.state.solvedFilter}
            onChange={this.toggleSolvedFilter}
          >
            <option value="all">All</option>
            {this.props.userAuthenticated ? (
              <Fragment>
                <option value="solvedByUser">Solved by me</option>
                <option value="unsolvedByUser">Unsolved by me</option>
              </Fragment>
            ) : (
              ""
            )}
            <option value="solved">Solved by anyone</option>
            <option value="unsolved">Unsolved by anyone</option>
          </select>
        </div>
      );
    }

    return (
      <Fragment>
        <div className="controller-group-container other-filters">
          {sortByDropdown}
          {solvedFilterDropdown}
          <div className="controller-group">
            <span className="controller-group-label">Search:&ensp;</span>
            <input
              type="search"
              id="searchFilter"
              value={this.state.searchFilter}
              onChange={this.changeSearchFilter}
            />
          </div>
        </div>
        <div className="controller-group-container categories">
          <div className="controller-group label">
            <span className="controller-group-label">Categories:&ensp;</span>
          </div>
          <div className="controller-group">
            {categories.map(category => {
              const title = category.Title;
              const slug = category.Slug;
              return (
                <ToggleButton
                  key={title}
                  label={title}
                  name="filter-by-category"
                  slug={slug}
                  isActive={this.state.categoryFilter === slug}
                  onClick={this.toggleCategoryFilter}
                />
              );
            })}
          </div>
        </div>
        <ChallengeList challenges={challenges} />
      </Fragment>
    );
  }
}

export default ChallengeListController;
