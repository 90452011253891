import toSlugCase from 'to-slug-case';

// https://stash.wolfram.com/projects/EDTECH/repos/wolframchallenges/browse/WolframChallenges/Kernel/Common.m?at=6773b98a8b594e9bc23c4d6e6a09dffde5623417#554
const categoryList = [
  "Start Here",
  "Mathematics",
  "Algorithms",
  "Computational Knowledge",
  "Words and Linguistics",
  "Sequences",
  "Primes",
  "Computational Sciences",
  "Lists",
  "Arrays"
];

export default categoryList.map(categoryTitle => {
  return {
    Title: categoryTitle,
    Slug: toSlugCase(categoryTitle)
  };
});
