import React from "react";

import "./style.css";

const checkMarkSVG = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
    width="20"
    height="20"
  >
    <path fill="#2db1c1" d="M0 0h40v40H0z" />
    <path fill="#fff" d="M12 18l-4 4 8 8 16-16-4-4-12 12z" />
  </svg>
);

const ChallengeSolvedDate = props => {
  const challenge = props.challenge;
  if (challenge.hasBeenSolved) {
    return (
      <p className="challenge-solved-date">
        {checkMarkSVG}
        <span className="text">
          Challenge last solved:{" "}
          {new Date(challenge.lastDateSolved).toLocaleDateString()}
        </span>
      </p>
    );
  } else {
    return null;
  }
};

export default ChallengeSolvedDate;
