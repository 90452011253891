import React, { PureComponent, Fragment } from "react";
import { Link } from "react-router-dom";
import MediaQuery from "react-responsive";

const CategoryItem = props => (
  <li>
    <Link to={"/categories/" + props.slug} className="category">
      {props.title}
    </Link>
  </li>
);

class ChallengeNavigation extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false
    };
  }
  toggleMenu = () => {
    this.setState(prevState => ({
      menuOpen: !prevState.menuOpen
    }));
  };
  render() {
    return (
      <Fragment>
        <div
          className={
            "section trending-challenges challenge-navigation-buttons " +
            (this.state.menuOpen ? "open" : "closed")
          }
        >
          <div className="wrap">
            <p
              className="button-frames"
              aria-expanded={this.state.menuOpen}
              aria-controls="hp-category-list"
            >
              <button onClick={this.toggleMenu}>
                Browse by Category {this.state.menuOpen ? "▾" : "▸"}
              </button>
              <Link to="/all" className="button">
                View All &raquo;
              </Link>
              <MediaQuery minWidth={450}>
                <Link to="/recent" className="button">
                  View Newest Challenges &raquo;
                </Link>
              </MediaQuery>
            </p>
          </div>
        </div>
        <div
          id="hp-category-list"
          className={"section " + (this.state.menuOpen ? "open" : "closed")}
        >
          <div className="wrap">
            <ul>
              {this.props.categories.map(category => (
                <CategoryItem
                  title={category.Title}
                  slug={category.Slug}
                  key={category.Slug}
                />
              ))}
            </ul>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ChallengeNavigation;
