import React from "react";
import DocumentTitle from "react-document-title";

import "./style.css";

import IntroSection from "./IntroSection";
import ChallengeTracks from "./ChallengeTracks";
import TrendingChallenges from "./TrendingChallenges";
import ChallengeNavigation from "./ChallengeNavigation";
import ResourcesSection from "./ResourcesSection";

const HomePage = props => {
  return (
    <DocumentTitle title="Wolfram Challenges: Programming Puzzles for the Wolfram Language">
      <main id="HomePage">
        <IntroSection />
        <ChallengeTracks tracks={props.tracks} />
        <TrendingChallenges challenges={props.challenges} />
        <ChallengeNavigation categories={props.categories} />
        <ResourcesSection />
      </main>
    </DocumentTitle>
  );
};

export default HomePage;
