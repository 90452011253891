import React from "react";
import { Link } from "react-router-dom";

import "./style.css";

const hourglassSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="#2db1c1"
  >
    <path d="M2 0v2h.75a4.63 4.63 0 0 0 2.1 3.95c.51.39 1.01.7 1.36 1.01.35.32.54.62.54 1.04 0 .41-.2.71-.54 1.03-.35.32-.85.64-1.36 1.02A4.62 4.62 0 0 0 2.75 14H2v2h12v-2h-.75a4.62 4.62 0 0 0-2.1-3.95c-.51-.38-1-.7-1.36-1.02-.34-.32-.54-.62-.54-1.03 0-.42.2-.72.54-1.04.35-.32.85-.62 1.36-1A4.64 4.64 0 0 0 13.25 2H14V0zm1.25 2h9.5c0 1.91-.92 2.81-1.9 3.55-.49.36-1 .68-1.4 1.05-.4.36-.7.81-.7 1.4 0 .58.3 1.03.7 1.4.4.37.91.68 1.4 1.05.98.73 1.9 1.63 1.9 3.55H12s-1-3-4-3-4 3-4 3h-.75c0-1.92.92-2.82 1.9-3.55.5-.37.99-.68 1.4-1.05.4-.37.7-.82.7-1.4 0-.59-.3-1.04-.7-1.4-.41-.37-.9-.69-1.4-1.05C4.17 4.8 3.25 3.9 3.25 2zM6 5c.5 1 1 1 2.02 2C9 6 9.5 6 10 5z" />
  </svg>
);
const speedometerSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="#2db1c1"
  >
    <path d="M11 6.5L8.47 8.32c-1.74-.49-2.98 1.66-1.7 2.93 1.28 1.27 3.41 0 2.91-1.73L11.5 7z" />
    <path d="M8 2a8 8 0 0 0-8 8 8 8 0 0 0 1.08 4h1.8a6.5 6.5 0 0 1-1.36-3.5h.98v-1h-.97a6.5 6.5 0 0 1 1.54-3.73l.69.7.7-.71-.68-.7A6.5 6.5 0 0 1 7.5 3.53v.98h1v-.97a6.5 6.5 0 0 1 3.73 1.54l-.7.69.71.7.7-.68a6.5 6.5 0 0 1 1.54 3.72h-.98v1h.97a6.5 6.5 0 0 1-1.36 3.5h1.8A8 8 0 0 0 16 10a8 8 0 0 0-8-8z" />
  </svg>
);
const codeSquaresSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="#2db1c1"
  >
    <path opacity=".5" d="M1 7h5v3h9v5H1V7z" />
    <path d="M0 0h16v1H0V0zm0 3h16v1H0V3zm0 3h16v1H0V6zm0 3h16v1H0V9zm0 3h16v1H0v-1zM0 1h1v14H0V1zm3 0h1v14H3V1zm3 0h1v14H6V1zm3 0h1v14H9V1zm3 0h1v14h-1V1zm3 0h1v14h-1V1zM0 15h16v1H0v-1z" />
  </svg>
);

const MetricCell = props => (
  <th scope="row" className="metric">
    <span className="align-icon" data-tip={props.tip}>
      {props.children}
    </span>
  </th>
);
const UserCell = props => (
  <td className="user">
    <span className="align-icon">
      <img width="16" height="16" alt="" src={props.user.ProfileImage} />{" "}
      {props.user.UserID}
    </span>
  </td>
);
const ScoreCell = props => <td className="score">{props.score}</td>;

const LeaderBoardData = props => {
  const data = props.leaderBoardData;
  return (
    <table className="leader-board-data">
      <caption className="visuallyhidden">Leaderboard</caption>
      <thead className="visuallyhidden">
        <tr>
          <th scope="col">Metric</th>
          <th scope="col">User</th>
          <th scope="col">Score</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <MetricCell tip="Score between 0 and 100 based on timing relative to a known solution. Higher is better.">
            {hourglassSvg} Speed Score
          </MetricCell>
          <UserCell user={data.TimingScore.User} />
          <ScoreCell score={data.TimingScore.Value.toFixed(2)} />
        </tr>
        <tr>
          <MetricCell tip="System memory used to run the solution.">
            {speedometerSvg} Memory Efficiency
          </MetricCell>
          <UserCell user={data.Memory.User} />
          <ScoreCell score={data.Memory.Value} />
        </tr>
        <tr>
          <MetricCell tip="Number of characters appearing in the InputForm of the code.">
            {codeSquaresSvg} Code Characters
          </MetricCell>
          <UserCell user={data.StringLength.User} />
          <ScoreCell score={data.StringLength.Value} />
        </tr>
        <tr>
          <MetricCell tip="ByteCount of the code.">
            {codeSquaresSvg} Code Bytes
          </MetricCell>
          <UserCell user={data.ByteCount.User} />
          <ScoreCell score={data.ByteCount.Value} />
        </tr>
        <tr>
          <MetricCell tip="LeafCount or number of subexpressions in the code.">
            {codeSquaresSvg} Code Leaves
          </MetricCell>
          <UserCell user={data.LeafCount.User} />
          <ScoreCell score={data.LeafCount.Value} />
        </tr>
      </tbody>
    </table>
  );
};

const LeaderBoardTile = props => {
  var heading = "";
  if (props.title) {
    heading = <h2>{props.title}</h2>;
  }
  if (props.title && props.challenge) {
    const solvedCount = props.challenge.Count;
    heading = (
      <h2>
        {props.title}{" "}
        <span className="solvedCount">
          (Solved {solvedCount} time{solvedCount !== 1 ? "s" : ""})
        </span>
      </h2>
    );
  }
  if (props.title && props.linkTo) {
    heading = (
      <h2>
        <Link to={props.linkTo}>{props.title} &raquo;</Link>
      </h2>
    );
  }
  return (
    <div className="leader-board">
      {heading}
      <LeaderBoardData leaderBoardData={props.leaderBoardData} />
    </div>
  );
};

export default LeaderBoardTile;
