import React from "react";
import { HashLink as Link } from "react-router-hash-link";

const IntroSection = () => (
  <div className="intro section">
    <div className="bg">
      <div className="wrap">
        <h1>Wolfram Challenges</h1>
        <p className="tagline">
          Wolfram Language Challenges in Programming &amp; Computational
          Thinking
        </p>
        <div className="get-started-frame">
          <Link to="#tracks" id="get-started">
            Get Started
          </Link>
        </div>
      </div>
    </div>
  </div>
);
export default IntroSection;
