import React from "react";
import toTitleCase from "to-title-case";
import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";
import take from "lodash/take";
import filter from "lodash/filter";
import reject from "lodash/reject";
import some from "lodash/some";
import DocumentTitle from "react-document-title";

import ChallengeListController from "../../components/ChallengeList/ChallengeListController";
import Loading from "../../components/Loading";
import NotFound from "../../components/NotFound";
import PleaseLogIn from "../../components/PleaseLogIn";

import "./style.css";

const CategoryView = props => {
  if (isEmpty(props.challenges) && props.allChallengesLoaded) {
    return <NotFound />;
  } else if (
    props.requireAuthentication === true &&
    props.userAuthenticated === false
  ) {
    return <PleaseLogIn />;
  } else if (
    isEmpty(props.challenges) ||
    (props.requireAuthentication === true && props.userAuthenticated === null)
  ) {
    return <Loading />;
  } else {
    return (
      <main id="CategoryPage">
        <div className="wrap">
          <h1>{props.pageTitle}</h1>
          <ChallengeListController
            challenges={props.challenges}
            categories={props.categories}
            userAuthenticated={props.userAuthenticated}
            defaultSortBy={props.defaultSortBy}
            defaultFilter={props.defaultFilter}
          />
        </div>
      </main>
    );
  }
};

export const AllPage = props => (
  <DocumentTitle title="All Challenges | Wolfram Challenges">
    <CategoryView
      challenges={props.challenges}
      categories={props.categories}
      userAuthenticated={props.userAuthenticated}
      pageTitle="All Challenges"
    />
  </DocumentTitle>
);
export const RecentPage = props => (
  <DocumentTitle title="Recent Challenges | Wolfram Challenges">
    <CategoryView
      challenges={take(orderBy(props.challenges, "Date", "desc"), 20)}
      categories={props.categories}
      userAuthenticated={props.userAuthenticated}
      defaultSortBy="recent"
      pageTitle="Recent Challenges"
    />
  </DocumentTitle>
);
export const PopularPage = props => (
  <DocumentTitle title="Popular Challenges | Wolfram Challenges">
    <CategoryView
      challenges={take(orderBy(props.challenges, "Count", "desc"), 20)}
      categories={props.categories}
      userAuthenticated={props.userAuthenticated}
      defaultSortBy="popularity"
      pageTitle="Popular Challenges"
    />
  </DocumentTitle>
);
export const SolvedByMePage = props => (
  <DocumentTitle title="Challenges Solved by Me | Wolfram Challenges">
    <CategoryView
      challenges={props.challenges}
      categories={props.categories}
      userAuthenticated={props.userAuthenticated}
      requireAuthentication={true}
      defaultFilter="solvedByUser"
      pageTitle="Solved By Me"
    />
  </DocumentTitle>
);
export const UnsolvedByMePage = props => (
  <DocumentTitle title="Challenges Unsolved by Me | Wolfram Challenges">
    <CategoryView
      challenges={props.challenges}
      categories={props.categories}
      userAuthenticated={props.userAuthenticated}
      requireAuthentication={true}
      defaultFilter="unsolvedByUser"
      pageTitle="Unsolved By Me"
    />
  </DocumentTitle>
);
export const CategoryPage = props => {
  const allChallenges = props.challenges;
  const categorySlug = props.match.params.category;
  const title = toTitleCase(categorySlug);
  return (
    <DocumentTitle title={title + " Challenges | Wolfram Challenges"}>
      <CategoryView
        challenges={filter(allChallenges, o => {
          return some(o.TagData, { TagSlug: categorySlug });
        })}
        categories={reject(props.categories, { Slug: categorySlug })}
        userAuthenticated={props.userAuthenticated}
        allChallengesLoaded={!isEmpty(allChallenges)}
        pageTitle={title}
      />
    </DocumentTitle>
  );
};
