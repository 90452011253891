import React from "react";
import orderBy from "lodash/orderBy";
import take from "lodash/take";

import ChallengeList from "../../components/ChallengeList/ChallengeList";

const filterTrendingChallenges = challenges => {
  return take(orderBy(challenges, "TrendingScore", "desc"), 8);
};

const TrendingChallenges = props => (
  <div className="section trending-challenges">
    <div className="wrap">
      <h2>Trending Challenges</h2>
      <ChallengeList challenges={filterTrendingChallenges(props.challenges)} />
    </div>
  </div>
);

export default TrendingChallenges;
